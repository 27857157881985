<template>
  <div>
    <h1>Update SKU # {{ skuId }} Form</h1>
    <a @click="$router.back()">Back</a>
    <div
      v-if="productData && skuData"
      class="mb-5 mt-2"
    >
      <h3>{{ skuData.name.en }}</h3>
      <p>product: {{ productData.title.en }}</p>
    </div>

    <sync-language-form
      v-if="skuData && formReady"
      :selected-ids="skuData.languages.map(c => c.id)"
      :sync-base-path="`/admin/skus/${skuData.id}/languages/sync`"
      class="mb-5"
      @change="refresh"
    />

    <sku-form
      v-if="productData && skuData"
      mode="create"
      :product="productData"
      :resource="skuData"
      :loading="loading"
      :errors="skuErrors"
      :disabled-fields="[ ['voucher', 'plan'].includes(productData.nature) ? 'stock' : '' ]"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import { useNotifySuccess } from '@/composables'
import SyncLanguageForm from '@/modules/common/views/language-resource/SyncLanguageForm.vue'
import router from '@/router'
import { } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import useProduct from '../useProduct'
import SkuForm from './SkuForm.vue'
import useSkuForm from './useSkuForm'

export default {
  components: { SkuForm, SyncLanguageForm },

  setup() {
    const { loadProduct, productData } = useProduct()
    const { updateSkuForm, skuErrors } = useSkuForm()

    const productId = router.currentRoute.query.product_id
    const skuId = router.currentRoute.query.sku_id

    const loading = ref(false)
    const skuData = ref(null)
    const formReady = ref(true)

    const handleSubmit = async form => updateSkuForm(skuId, form).then(res => {
      console.log('form submit', form, res)
      router.push({
        name: 'product-view',
        params: { id: productId },
        query: { tab: 'SKUS' },
      })
      useNotifySuccess({ content: 'SKU Update Succeeded.' })
    })

    const refresh = () => {
      loadProduct(productId).then(res => {
        skuData.value = res.data.data.skus.find(s => s.id === parseInt(skuId))
        formReady.value = false
        setTimeout(() => (formReady.value = true))
      })
    }

    onMounted(() => refresh())

    return {
      productData,
      skuData,
      skuId,
      refresh,
      formReady,

      loading,
      handleSubmit,
      skuErrors,
    }
  },
}
</script>
